@import 'style-constants';

.my-upcoming-interview-card {
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px $black-grey-shadow;
    border-radius: 20px;
    padding: 30px 40px;
    display: flex;
    justify-content: space-between;

    .position {
        font-size: $medium-title;
        line-height: 22px;
        font-weight: 500;
        letter-spacing: 0.61px;
        color: $purple-dark;
        margin-bottom: 11px;
    }

    .time-range {
        font-size: $medium;
        line-height: 19px;
        letter-spacing: 0.15px;
        color: $medium-dark-shade-blue;
        margin-bottom: 16px;
    }

    .company {
        width: fit-content;
        display: grid;
        grid-auto-flow: column;
        gap: 8px;
        align-items: center;
        font-size: $medium;
        line-height: 19px;
        letter-spacing: 0.54px;
        color: $purple-dark;
        
        .company-icon {
            width: 15px;
            height: 15px;
        }
    }

    .location {
        font-size: $medium;
        line-height: 19px;
        letter-spacing: 0.15px;
        color: $medium-dark-shade-blue;
        margin-top: 32px;

        span {
            text-decoration: underline;
        }
    }

    .day-month-container {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: flex-end;

        .day {
            font-size: 30px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.3px;
            color: $purple-dark;
            margin-bottom: 7px;
        }

        .month {
            font-size: $medium;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0.16px;
            color: $purple-dark;
        }
    }
}