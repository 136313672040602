@import 'style-constants.scss';

.candidates-list-actions {
    width: 100%;
    margin: 30px 0px 22px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .candidates-sort-select {
        & > div {
            width: 100%;
            height: 20px;
            min-width: 60px;
        }

        &::before {
            content: url('../../../assets/sort-icon.svg');
            position: absolute;
            left: 7px;
            top: 5px;
        }

        
        &::after {
            content: "Sort by";
            position: absolute;
            text-align: left;
            font: normal normal normal 14px/17px Montserrat;
            letter-spacing: 0.24px;
            color: $grey-dark;
            right: 29px;
            top: 7px;
        }
        
        &.bg {
            & > div {
                min-width: 105px;
            }

            &::after {
                content: "Сортирай по"
            }
        }
    }

    .candidates-search-input {
        background-color: $actions-background;
        border: 1px solid $actions-border;
        border-radius: 4px;
        padding-left: 18px;
        width: 100%;
        margin-left: 20px;

        svg path {
            fill: $grey-dark;
        }
    }
}
